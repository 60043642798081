@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(rgb(90, 29, 204), rgb(200, 90, 204));
  border-radius: 20px;
}
::-webkit-scrollbar-track {
  background-color: rgb(37, 37, 37);
}
html {
  --theme: rgb(22, 22, 22);
  scroll-behavior: smooth;
  margin: 0;
  overflow-x: hidden;
}

.body {
  margin-top: 50px;
  min-height: 100vh;
}
a {
  text-decoration: none;
}
nav {
  height: 60px;
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  z-index: 10;
}
.scroll {
  position: fixed;
  top: 0;
  animation: nav-squeeze 0.5s ease-in-out;
}
@keyframes nav-squeeze {
  from {
    height: 0px;
    overflow: hidden;
  }
  to {
    height: 60px;
    overflow: visible;
  }
}
nav .logo {
  font-weight: 500;
  font-size: 24px;
  color: #2c358b;
  width: 25%;
  display: flex;
  justify-content: flex-end;
  text-transform: capitalize;
}
.logo a {
  color: #2c358b;
}
nav ul {
  display: flex;
  width: 75%;
  justify-content: flex-start;
}

nav ul li {
  padding: 0 30px;
  list-style: none;
}
nav ul li a {
  text-decoration: none;
  padding: 5px 25px;
  color: black;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  background: linear-gradient(-20deg, transparent 50%, var(--theme) 50%);
  background-position: 100%;
  background-size: 400%;
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  color: black;
}
nav ul li a:hover {
  background-position: 0;
  color: white;
}

.welcome img {
  width: 100%;
  height: 700px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.welcome h1{
  font-weight: 600;
}
.welcome {
  text-align: left;
  width: 100%;
  height: 700px;
  padding: 245px 70px;
  background-color: #110531b7;
  color: #fff;
}
@keyframes fade {
  from {
    opacity: 20%;
    transform: translateX(100%);
  }
  to {
    opacity: 100%;
    transform: translateX(0%);
  }
}
@keyframes top-wipe{

  from{
    opacity: 20%;
    transform: translateY(-100%);
  }
  to{
    opacity: 100%;
    transform: translateY(0%);
  }
}
.welcome p {
  font-size: 18px;
  animation: fade 0.6s ease-in;
}
.welcome h1 {
  margin-bottom: 20px;
  animation: fade 0.6s ease-in;
}
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
}

.btn-con {
  margin-top: 20px;
}

.btn-t-1 {
  cursor: pointer;
  padding: 10px 20px;
  font-size: 20px;
  border-radius: 8px;
  border: none;
  background: linear-gradient(50deg, #fff 50%, rgb(101, 198, 228) 50%);
  background-position: 100%;
  background-size: 400%;
  transition: all 0.3s ease-in-out;
  color: #000;
  animation: top-wipe 0.4s ease-in;
}
.btn-t-1:hover {
  background-position: 0;
  color: rgb(22, 38, 126);
}
@keyframes nav {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

.card-container {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.image {
  position: relative;
  flex: 1;
  max-width: 500px;
  height: 500px;
  background: #000;
  margin: 20px;
  overflow: hidden;
  border-radius: 8px;
}
.image img {
  opacity: 0.5;
  position: relative;
  vertical-align: top;
  transition: all 0.6s;
  height: 100%;
  width: 100%;
}
.image:hover img {
  opacity: 0.2;
}
.image .details {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  height: 100%;
  width: 100%;
}
.image .details h2 {
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 70px;
  transition: all 0.4s;
}
.image:hover .details h2 {
  transform: translateY(-30px);
}
.image .details p {
  margin: 30px 30px 0 30px;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  transition: all 0.4s;
}
.image:hover .details p {
  transform: translateY(-40px);
}
.image .more {
  position: absolute;
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  bottom: -80px;
  transition: all 0.6s;
}
.image:hover .more {
  bottom: 0;
}
.read-more {
  text-decoration: none;
  padding: 10px 20px;
  font-size: 20px;
  border-radius: 8px;
  border: none;
  background: linear-gradient(50deg, rgb(0, 0, 0) 50%, rgb(101, 198, 228) 50%);
  background-position: 100%;
  background-size: 400%;
  transition: all 0.3s ease-in-out;
  color: #000;
}
.read-more:hover {
  background-position: 0;
  color: rgb(255, 255, 255);
}
.footer {
  width: 100%;
  height: 400px;
  background-color: rgb(243, 243, 243);
  display: flex;
  justify-content: center;
  bottom: 0;
}
.container {
  width: 250px;
  height: 300px;
  text-align: left;
  margin-top: 20px;
}
.container ul {
  list-style: none;
}
.container h4 {
  font-weight: 700;
  color: #2c358b;
  margin: 5px 0;
  transition: all 0.3s;
}
.container h4:hover {
  color: #260e69;
}

.container a {
  text-decoration: none;
  color: rgb(94, 94, 94);
  transition: all 200ms;
  font-size: 14px;
}
.container a:hover {
  color: rgb(24, 24, 24);
}
.last {
  width: 100%;
  height: 50px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  background-color: rgb(243, 243, 243);
}
.contact {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(33, 9, 54);
}
.contact form {
  animation: top-wipe 0.6s ease-in-out;
  border-radius: 15px;
  padding: 50px 5%;
  height: 500px;
  width: 35%;
  background-color: #fff;
  z-index: 2;
}
.contact form .head {
  font-weight: 400;
  font-size: 30px;
}
.contact form .frm {
  width: 90%;
  padding: 10px 2%;
}
.contact form .main {
  margin-top: 30px;
  display: flex;
  justify-items: flex-start;
  flex-direction: column;
}
.contact form .main input {
  margin-bottom: 20px;
  height: 40px;
  width: 95%;
  border: none;
  border-bottom: 1px solid #968bb6;
  padding: 0 10px;
}
.contact form .main textarea {
  border: none;
  border-bottom: 1px solid #968bb6;
  padding: 0 10px;
  max-width: 95%;
  min-width: 95%;
  height: 60px;
  min-height: 40px;
  max-height: 200px;
}
.contact form .main textarea:focus {
  outline: none;
}
.contact form .main input:focus {
  outline: none;
}
.frm {
  height: 100%;
}
.send {
  cursor: pointer;
  margin-top: 30px;
  padding: 5px 20px;
  max-width: 300px;
  border-radius: 8px;
  border: none;
  background: linear-gradient(
    45deg,
    rgb(90, 29, 204),
    rgb(205, 26, 211),
    rgb(200, 90, 204),
    rgb(164, 249, 255)
  );
  background-position: 100%;
  background-size: 400%;
  color: #260e69;
  font-size: 18px;
  font-weight: 500;
  transition: all 0.3s;
}
.send:hover {
  background-position: 0;
  color: #fff;
}
.info {
  width: 100%;
  height: 60px;
  background-color: rgb(17, 17, 17);
  display: flex;
  color: #fff;
  justify-content: flex-start;
  align-items: center;
}
.info div {
  padding: 0 2%;
}
.info a,
.info span {
  color: #9ea7ff;
  text-decoration: none;
}

.contact img.bg {
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0.3;
}
.contact .text {
  color: #fff;
  font-size: 27px;
  text-align: center;
  z-index: 2;
  animation: fade 0.6s ease-in-out;
}

.pricing{
  min-height: 100vh;
  width: 100%;
  background-color: #110531;
}
.pricing img{
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.4;
  z-index: 1;
}
.index-2{
  z-index: 2;
  position: absolute;
  top: 50px;
  left: 0;
  display: flex ;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
}
.pr-container{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  flex-wrap: wrap;
  z-index: 2;
}
.card-pr{
  animation: fade 0.6s ease-in-out;
  position: relative;
  width: 400px;
  height: 600px;
  margin: 30px;
  box-shadow: 30px 30px 60px rgba(0, 0, 0, 0.5) ;
  background-color: rgba(255, 255, 255, 0.2);
  justify-content: center;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: 10px;
  backdrop-filter: blur(5px);
  transition: 0.2s all ease-in-out;
}
.content-pr{
  padding: 20px;
  text-align: center;
  transition: 0.5s;
  width: 90%;
}

.pr{
  width: 100px !important;
}
.content-pr h2{
  font-size: 1.8em;
  margin-bottom: 20px;
  width: 100%;
  border-bottom: 2px solid rgba(255, 255, 255, 0.212);
  color: #fff;
  z-index: 2;
}
.content-pr p{
  width: 100%;
  height: 200px;
  margin-top: 30px;
  margin-bottom: 30px;
  color: rgb(253, 253, 253);
  overflow-y: hidden;
} 
.card-pr:hover{
  transform: translateY(-50px);

}
.content-pr p ul{
  list-style-type: none;
}
.gld h2{
  color:gold;
  
}
.sl{
  background-color: rgba(153, 98, 216, 0.2);
}
.sl h2{
  color: rgb(255, 94, 94);
}
.gld{
  background: linear-gradient(rgba(255, 153, 0, 0.2) 50%,rgba(219, 23, 187, 0.2) ) ;
}
.br h2{
  color: rgb(116, 57, 57);
}
.br{
  background: linear-gradient(rgba(97, 250, 255, 0.3), rgba(110, 94, 23, 0.3));
}
.br:hover{
  background-position: 0;
}
.gold {
  cursor: pointer;
  margin-top: 30px;
  padding: 5px 20px;
  max-width: 300px;
  border-radius: 8px;
  border: none;
  background: linear-gradient(
    45deg,
    rgb(112, 35, 35),
    rgb(182, 149, 42),
    rgb(182, 149, 42),
    rgb(182, 93, 42)
  );
  background-position: 100%;
  background-size: 400%;
  color: #411a1a;
  font-size: 18px;
  font-weight: 500;
  transition: all 0.3s;
}
.gold:hover {
  background-position: 0;
  color: #fff;
}
.ruby {
  cursor: pointer;
  margin-top: 30px;
  padding: 5px 20px;
  max-width: 300px;
  border-radius: 8px;
  border: none;
  background: linear-gradient(
    45deg,
    rgb(167, 23, 23),
    rgb(182, 42, 163),
    rgb(255, 124, 124),
    rgb(110, 64, 219)
  );
  background-position: 100%;
  background-size: 400%;
  color: #1d1342;
  font-size: 18px;
  font-weight: 500;
  transition: all 0.3s;
}
.ruby:hover {
  background-position: 0;
  color: #fff;
}
.content-pr h4{
  color: #fff;
  font-size: 30px;
  font-weight: 400;
}
